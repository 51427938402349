<template>
  <Loader v-if="pageLoading" text="Loading All Sets" />
  <div v-else class="office-custom-main">
    <Dropdown :items="allAvailableSets" v-model:selected="selectedSet" placeholder="Set" />
    
    <Loader v-if="loadingSet" :text="`Loading set ${selectedSet.value}`" />
    <template v-else>
      <Dropdown placeholder="Industry" :items="industryListing" v-model:selected="selectedIndustry" />
      <Collapsible v-if="selectedIndustry" title="Department Listing">
        <Collapsible class="department-collapsible" v-for="(d, dInd) in departmentList" :key="dInd" :title="d.value">
          <Collapsible class="position-collapsible" v-for="(p, pInd) in positionList[d.value]" :key="pInd" :title="p.value">
            To add type of seatings
          </Collapsible>
          <Dropdown placeholder="Positions" :items="positionListing" v-model:selected="selectedPosition[d.value]" />
          <Button class="collapsible-button" theme="submit" @click="addPositionClicked(d)">Add Position</Button>
        </Collapsible>
        <Dropdown placeholder="Departments" :items="departmentListing" v-model:selected="selectedDepartment" />
        <Button class="collapsible-button" theme="submit" @click="addDepartmentClicked">Add Department</Button>
      </Collapsible>
    </template>
  </div>
</template>

<script setup>
import { ref, watch, onMounted } from 'vue';
import { get } from '../../js/apiCall';

// #region Data
const loadingSet = ref(false); //This is use to show the loading when getting the set by id
const pageLoading = ref(false); // This is use to show when getting all the available sets
const selectedSet = ref(null); // This is the selected set from the dropdown

const allAvailableSets = ref([]); // This is the listing of the available sets
const industryListing = ref([]); //The list of industry
const selectedIndustry = ref(null); //The selected indsutry

const departmentListing = ref([]); //The list of department
const selectedDepartment = ref(null); //The selected department
const departmentList = ref([]); // This is the list of departments that has been added

const positionListing = ref([]); //The list of position
const selectedPosition = ref({}); //The selected position
const positionList = ref({}); // This is the list of positions that has been added

const seatingListing = ref([]); //The list of seating
// #endregion Data

// #region Methods
const getAllSets = async (init) => {
  pageLoading.value = true;
  const allSets = await get('/CustomOffice/GetComstomOfficeDefaultSets');
  allAvailableSets.value = allSets.map(s => ({ value: s }));

  if (!init) {
    pageLoading.value = false;
  }
}
const getSetById = async () => {
  // Clear the value first
  selectedIndustry.value = null;

  // Getting the set by id
  loadingSet.value = true;
  const setById = await get(`/CustomOffice/GetCustomOfficeDefaultSet?setId=${selectedSet.value.value}`);
  loadingSet.value = false;

  // Auto populate the details
  selectedIndustry.value = industryListing.value.find(i => i.uid == setById[0].industry_uid);
  departmentList.value = setById.map(or => departmentListing.value.find(d => d.uid == or.department_uid));
  const positionSet = {};
  departmentList.value.forEach(d => {
    const currentDept = setById.find(o => o.department_uid == d.uid);
    positionSet[d.value] = currentDept.positions.map(curr => positionListing.value.find(p => p.uid == curr.position_uid));
  });
  positionList.value = positionSet;
}

const addDepartmentClicked = () => {
  if (selectedDepartment.value) {
    departmentList.value.push(selectedDepartment.value);
    selectedPosition.value[selectedDepartment.value.value] = null;
    selectedDepartment.value = null;
  }
}
const addPositionClicked = (department) => {
  if (selectedPosition.value[department.value]) {
    if (positionList.value[department.value]) {
      positionList.value[department.value].push(selectedPosition.value[department.value]);
    } else {
      positionList.value[department.value] = [selectedPosition.value[department.value]];
    }

    selectedPosition.value[department.value] = null;
  }
}
// #endregion Methods

// #region Lifecycle
onMounted(async() => {
  pageLoading.value = true;
  
  // Getting all the available sets
  await getAllSets(true);

  // Getting all the categories
  const categoryListing = await get('/CustomOffice/GetCustomOfficeCategories');

  // Getting details for all the categories
  const subCategoryListing = await get('CustomOffice/GetCustomOffices');

  // Formatting to get all the categories
  const allCategories = categoryListing.map(cat => {
    // Get all the subcateogries
    let subcategory = subCategoryListing.filter(sub => sub.category_uid == cat.category_uid);
    subcategory = subcategory.map(sub => ({ ...sub, value: sub.name }));

    return {
      ...cat,
      value: cat.category,
      subCategory: subcategory
    };
  });

  // Getting all the dropdown listing
  industryListing.value = allCategories.find(cat => cat.category_uid == 1).subCategory;
  departmentListing.value = allCategories.find(cat => cat.category_uid == 2).subCategory;
  positionListing.value = allCategories.find(cat => cat.category_uid == 3).subCategory;
  seatingListing.value = allCategories.find(cat => cat.category_uid == 4).subCategory;

  pageLoading.value = false;
});
// #endregion Lifecycle

// #region Watcher
watch(selectedSet, (val) => {
  if (val) {
    getSetById();
  }
})
// #endregion Watcher
</script>

<style scoped>
.office-custom-main {
  display: flex;
  flex-direction: column;
  gap: 10px;
}
.department-collapsible {
  background-color: rgb(230, 255, 230);
}
.position-collapsible {
  background-color: rgb(196, 255, 196);
}
.collapsible-button {
  width: fit-content;
  padding-left: 50px;
  padding-right: 50px;
}
.save-button {
  width: fit-content;
  padding-left: 100px;
  padding-right: 100px;
  align-self: flex-end;
}
</style>